export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: false,
  locales: [{"code":"en","iso":"en-US","file":"en.json","shopifyApiDomain":"shop.stg-en.blackroll.com","shopifyName":"oneworld-stg-en.myshopify.com","shopifyApiAccessToken":"e8232fe965abafadc875e791a9a68f61","newsletterListId":"0cc61cd256","tsid":"X4FBC91AEC4F1EDC7A811C9675FE11C49"},{"code":"de","iso":"de-DE","file":"de.json","shopifyApiDomain":"shop.stg-de.blackroll.com","shopifyName":"oneworld-stg-de.myshopify.com","shopifyApiAccessToken":"6998750b00681f4723280b898a58733c","newsletterListId":"6e01c1cfbe","tsid":"XE021CE54CCE4476F940AAA5B354AE716"},{"code":"fr","iso":"fr-FR","file":"fr.json","shopifyApiDomain":"shop.stg-en.blackroll.com","shopifyName":"oneworld-stg-en.myshopify.com","shopifyApiAccessToken":"e8232fe965abafadc875e791a9a68f61","newsletterListId":"d40d5938ef","tsid":"X33BFA2C3B8597CBD35FC97E926A8D6D4"},{"code":"nl","iso":"nl-NL","file":"nl.json","shopifyApiDomain":"shop.stg-en.blackroll.com","shopifyName":"oneworld-stg-en.myshopify.com","shopifyApiAccessToken":"e8232fe965abafadc875e791a9a68f61","newsletterListId":"16d089013c","tsid":"X856B1E7D2A6888E363AFBE6D1752BF8F"},{"code":"ch-de","iso":"de-CH","file":"ch-de.json","shopifyApiDomain":"oneworld-stg-de-ch.myshopify.com","shopifyName":"oneworld-stg-de-ch.myshopify.com","shopifyApiAccessToken":"0367b15127ca5a76334775d0d443522e","newsletterListId":"bc3f4008c6","tsid":"X583A3B28986EF6CB6EB3C69C8CA82694"},{"code":"ch-fr","iso":"fr-CH","file":"ch-fr.json","shopifyApiDomain":"oneworld-stg-de-ch.myshopify.com","shopifyName":"oneworld-stg-de-ch.myshopify.com","shopifyApiAccessToken":"0367b15127ca5a76334775d0d443522e","newsletterListId":"bc3f4008c6","tsid":"XAFD97AD24F7FE9DC87EF7003368BC30A"}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "~/locales/",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: false,
  pages: {"about/index":{"en":"/about-blackroll","de":"/ueber-blackroll","fr":"/a-propos-de-blackroll","nl":"/over-blackroll","ch-de":"/ueber-blackroll","ch-fr":"/a-propos-de-blackroll"},"about/_slug/index":{"en":"/about-blackroll/:slug","de":"/ueber-blackroll/:slug","fr":"/a-propos-de-blackroll/:slug","nl":"/over-blackroll/:slug","ch-de":"/ueber-blackroll/:slug","ch-fr":"/a-propos-de-blackroll/:slug"},"exercises/index":{"fr":"/exercices","de":"/uebungen","nl":"/oefeningen","ch-de":"/uebungen","ch-fr":"/exercices"},"experts/index":{"de":"/experten","nl":"/onze-experts","ch-de":"/experten"},"experts/_slug/index":{"de":"/experten/:slug","nl":"/onze-experts/:slug","ch-de":"/experten/:slug"},"knowledge/index":{"de":"/wissen","fr":"/connaissances","nl":"/kennis","ch-de":"/wissen","ch-fr":"/connaissances"},"knowledge/_topic/index":{"de":"/wissen/:topic","fr":"/connaissances/:topic","nl":"/kennis/:topic","ch-de":"/wissen/:topic","ch-fr":"/connaissances/:topic"},"knowledge/_topic/_category":{"de":"/wissen/:topic/:category","fr":"/connaissances/:topic/:category","nl":"/kennis/:topic/:category","ch-de":"/wissen/:topic/:category","ch-fr":"/connaissances/:topic/:category"},"knowledge/category/_category":{"de":"/wissen/category/:category","fr":"/connaissances/category/:category","nl":"/kennis/category/:category","ch-de":"/wissen/category/:category","ch-fr":"/connaissances/category/:category"},"article/_slug/index":{"de":"/artikel/:slug","ch-de":"/artikel/:slug"}},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  vuei18n: "~/i18n.config.js",
  normalizedLocales: [{"code":"en","iso":"en-US","file":"en.json","shopifyApiDomain":"shop.stg-en.blackroll.com","shopifyName":"oneworld-stg-en.myshopify.com","shopifyApiAccessToken":"e8232fe965abafadc875e791a9a68f61","newsletterListId":"0cc61cd256","tsid":"X4FBC91AEC4F1EDC7A811C9675FE11C49"},{"code":"de","iso":"de-DE","file":"de.json","shopifyApiDomain":"shop.stg-de.blackroll.com","shopifyName":"oneworld-stg-de.myshopify.com","shopifyApiAccessToken":"6998750b00681f4723280b898a58733c","newsletterListId":"6e01c1cfbe","tsid":"XE021CE54CCE4476F940AAA5B354AE716"},{"code":"fr","iso":"fr-FR","file":"fr.json","shopifyApiDomain":"shop.stg-en.blackroll.com","shopifyName":"oneworld-stg-en.myshopify.com","shopifyApiAccessToken":"e8232fe965abafadc875e791a9a68f61","newsletterListId":"d40d5938ef","tsid":"X33BFA2C3B8597CBD35FC97E926A8D6D4"},{"code":"nl","iso":"nl-NL","file":"nl.json","shopifyApiDomain":"shop.stg-en.blackroll.com","shopifyName":"oneworld-stg-en.myshopify.com","shopifyApiAccessToken":"e8232fe965abafadc875e791a9a68f61","newsletterListId":"16d089013c","tsid":"X856B1E7D2A6888E363AFBE6D1752BF8F"},{"code":"ch-de","iso":"de-CH","file":"ch-de.json","shopifyApiDomain":"oneworld-stg-de-ch.myshopify.com","shopifyName":"oneworld-stg-de-ch.myshopify.com","shopifyApiAccessToken":"0367b15127ca5a76334775d0d443522e","newsletterListId":"bc3f4008c6","tsid":"X583A3B28986EF6CB6EB3C69C8CA82694"},{"code":"ch-fr","iso":"fr-CH","file":"ch-fr.json","shopifyApiDomain":"oneworld-stg-de-ch.myshopify.com","shopifyName":"oneworld-stg-de-ch.myshopify.com","shopifyApiAccessToken":"0367b15127ca5a76334775d0d443522e","newsletterListId":"bc3f4008c6","tsid":"XAFD97AD24F7FE9DC87EF7003368BC30A"}],
  localeCodes: ["en","de","fr","nl","ch-de","ch-fr"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "e",
  3: "n",
  4: ".",
  5: "j",
  6: "s",
  7: "o",
  8: "n",
  9: "\"",
  10: ":",
  11: "\"",
  12: ".",
  13: ".",
  14: "/",
  15: "l",
  16: "o",
  17: "c",
  18: "a",
  19: "l",
  20: "e",
  21: "s",
  22: "/",
  23: "e",
  24: "n",
  25: ".",
  26: "j",
  27: "s",
  28: "o",
  29: "n",
  30: "\"",
  31: ",",
  32: "\"",
  33: "d",
  34: "e",
  35: ".",
  36: "j",
  37: "s",
  38: "o",
  39: "n",
  40: "\"",
  41: ":",
  42: "\"",
  43: ".",
  44: ".",
  45: "/",
  46: "l",
  47: "o",
  48: "c",
  49: "a",
  50: "l",
  51: "e",
  52: "s",
  53: "/",
  54: "d",
  55: "e",
  56: ".",
  57: "j",
  58: "s",
  59: "o",
  60: "n",
  61: "\"",
  62: ",",
  63: "\"",
  64: "f",
  65: "r",
  66: ".",
  67: "j",
  68: "s",
  69: "o",
  70: "n",
  71: "\"",
  72: ":",
  73: "\"",
  74: ".",
  75: ".",
  76: "/",
  77: "l",
  78: "o",
  79: "c",
  80: "a",
  81: "l",
  82: "e",
  83: "s",
  84: "/",
  85: "f",
  86: "r",
  87: ".",
  88: "j",
  89: "s",
  90: "o",
  91: "n",
  92: "\"",
  93: ",",
  94: "\"",
  95: "n",
  96: "l",
  97: ".",
  98: "j",
  99: "s",
  100: "o",
  101: "n",
  102: "\"",
  103: ":",
  104: "\"",
  105: ".",
  106: ".",
  107: "/",
  108: "l",
  109: "o",
  110: "c",
  111: "a",
  112: "l",
  113: "e",
  114: "s",
  115: "/",
  116: "n",
  117: "l",
  118: ".",
  119: "j",
  120: "s",
  121: "o",
  122: "n",
  123: "\"",
  124: ",",
  125: "\"",
  126: "c",
  127: "h",
  128: "-",
  129: "d",
  130: "e",
  131: ".",
  132: "j",
  133: "s",
  134: "o",
  135: "n",
  136: "\"",
  137: ":",
  138: "\"",
  139: ".",
  140: ".",
  141: "/",
  142: "l",
  143: "o",
  144: "c",
  145: "a",
  146: "l",
  147: "e",
  148: "s",
  149: "/",
  150: "c",
  151: "h",
  152: "-",
  153: "d",
  154: "e",
  155: ".",
  156: "j",
  157: "s",
  158: "o",
  159: "n",
  160: "\"",
  161: ",",
  162: "\"",
  163: "c",
  164: "h",
  165: "-",
  166: "f",
  167: "r",
  168: ".",
  169: "j",
  170: "s",
  171: "o",
  172: "n",
  173: "\"",
  174: ":",
  175: "\"",
  176: ".",
  177: ".",
  178: "/",
  179: "l",
  180: "o",
  181: "c",
  182: "a",
  183: "l",
  184: "e",
  185: "s",
  186: "/",
  187: "c",
  188: "h",
  189: "-",
  190: "f",
  191: "r",
  192: ".",
  193: "j",
  194: "s",
  195: "o",
  196: "n",
  197: "\"",
  198: "}",
}

export const localeMessages = {
  'en.json': () => import('../../locales/en.json' /* webpackChunkName: "lang-en.json" */),
  'de.json': () => import('../../locales/de.json' /* webpackChunkName: "lang-de.json" */),
  'fr.json': () => import('../../locales/fr.json' /* webpackChunkName: "lang-fr.json" */),
  'nl.json': () => import('../../locales/nl.json' /* webpackChunkName: "lang-nl.json" */),
  'ch-de.json': () => import('../../locales/ch-de.json' /* webpackChunkName: "lang-ch-de.json" */),
  'ch-fr.json': () => import('../../locales/ch-fr.json' /* webpackChunkName: "lang-ch-fr.json" */),
}
